/**
 * @namespace components
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slider from 'react-slick';
import MediaQuery from 'react-responsive';

import ProLink from '../../shared/ProLink/ProLink';
import CustomPaging from '../../shared/CustomPaging/CustomPaging';
import { isClient, dataLayer, useProgressiveImage } from '../../../helpers';

import './HomepageHero.scss';
import AccessibleCarouselArrow from '../../shared/AccessibleCarouselButton/AccessibleCarouselButton';

/**
 * @class HomepageHero
 *
 * Specific home page banner with a configuration to allow peeking of BlockLinks
 *
 * @memberof components
 *
 *
 * @property {string} name - Name of the module, provided dynamically
 * @property {object} data - Primary object to insert data props into component
 * @property {string=} data.logicalName - Logical name of the module, used as the HTML node ID
 * @property {array} data.slides - Nested array of slides
 * @property {string=} data.slides.title - Title of the slide
 * @property {string=} data.slides.subtitle - Subtitle of the slide
 * @property {boolean=} data.slides.dark - Sets the dark theme on the slide
 * @property {string=} data.slides.alignment - Dictates text direction. oneOf: left, center, right
 * @property {object=} data.slides.image - Image object containing desktop and mobile images
 * @property {string=} data.slides.image.desktop - Desktop image resource
 * @property {string=} data.slides.image.mobile - Mobile image resource
 * @property {array=} data.slides.buttons - Array of buttons in standard button format
 * @property {string} data.slides.buttons.title - Display title of button
 * @property {string} data.slides.buttons.link - Link passed to HREF of button anchor
 *
 * @example
 * return (
 *   <HomepageHero data={data} name="HomepageHero" />
 * )
 */

const HomepageHero = (props = {}) => {
    const { data = {}, name, loading } = props;
    const { slides = [], logicalName = 'home-page-hero' } = data;
    const [hasSlideImageLoaded, setHasSlideImageLoaded] = useState(false);

    const sliderSettings = {
        accessibility: true,
        arrows: slides && slides.length > 1,
        prevArrow: <AccessibleCarouselArrow />,
        nextArrow: <AccessibleCarouselArrow />,
        beforeChange: () =>
            dataLayer.triggerEvent('slider_arrow_clicked', {
                id: logicalName,
                name,
            }),
        customPaging: CustomPaging,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    if (loading.page) {
        return <div id={logicalName} data-testid="loading" className="loading-screen" />;
    }

    return (
        <div
            id={logicalName}
            data-testid="home-page-hero"
            className={classNames({
                module: true,
                'container-fluid': true,
                'homepage-hero home-hero': true,
                initialised: isClient(),
            })}
            style={{ backgroundColor: hasSlideImageLoaded ? 'transparent' : 'black' }}
        >
            <MediaQuery query="(min-width: 1025px)">
                {matches => {
                    return (
                        <Slider {...sliderSettings}>
                            {/* slides */}
                            {slides &&
                                slides.length > 0 &&
                                slides.map((slide = {}, i) => {
                                    const { title, subtitle, dark, alignment, buttons = [], image = {} } = slide;
                                    const { desktop, mobile } = image;
                                    const backgroundImageSrc =
                                        matches || !isClient()
                                            ? `url('${useProgressiveImage(desktop)}')`
                                            : `url('${useProgressiveImage(mobile)}')`;

                                    backgroundImageSrc ? setHasSlideImageLoaded(true) : setHasSlideImageLoaded(false);

                                    return (
                                        <div
                                            data-testid={`slide-${i}`}
                                            key={i}
                                            className={classNames({
                                                'container-fluid': true,
                                                'homepage-hero-slide': true,
                                                'dark-theme': dark,
                                                [`text-${alignment}`]: true,
                                            })}
                                        >
                                            <div
                                                className="bg-cover"
                                                style={{
                                                    backgroundImage: backgroundImageSrc,
                                                }}
                                            />
                                            <div className="row">
                                                <div className="container">
                                                    <div className="row">
                                                        <div
                                                            className={classNames({
                                                                'inner-box': true,
                                                                'col-md-6': true,
                                                                'offset-md-3': alignment === 'center',
                                                                'offset-md-6': alignment === 'right',
                                                            })}
                                                        >
                                                            {title && <h1>{title}</h1>}
                                                            {subtitle && <h2>{subtitle}</h2>}
                                                            {buttons && buttons.length > 0 && (
                                                                <div className="button-wrapper">
                                                                    {buttons.map((button = {}, i) => {
                                                                        const { title, link } = button;
                                                                        return (
                                                                            <ProLink
                                                                                key={i}
                                                                                to={link}
                                                                                className={classNames({
                                                                                    btn: true,
                                                                                    'btn-lg': true,
                                                                                    'btn-blue': !dark,
                                                                                })}
                                                                            >
                                                                                {title}
                                                                            </ProLink>
                                                                        );
                                                                    })}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </Slider>
                    );
                }}
            </MediaQuery>
        </div>
    );
};

HomepageHero.propTypes = {
    name: PropTypes.string.isRequired,
    data: PropTypes.shape({
        logicalName: PropTypes.string,
        slides: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                subtitle: PropTypes.string,
                dark: PropTypes.bool,
                alignment: PropTypes.oneOf(['left', 'center', 'right']),
                image: PropTypes.shape({
                    desktop: PropTypes.string,
                    mobile: PropTypes.string,
                }),
                buttons: PropTypes.arrayOf(
                    PropTypes.shape({
                        title: PropTypes.string,
                        link: PropTypes.string,
                    })
                ),
            })
        ),
    }),
};

export default HomepageHero;

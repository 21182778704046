/**
 * @namespace components
 */

import React from 'react';
import PropTypes from 'prop-types';

import { miniGazelle } from '../../../helpers';

/**
 * @class CustomPaging
 *
 * @description Custom paging component-as-a-function that is passed to Slick slides to achieve accessibility on clickable slide pips
 *
 * @memberof components
 *
 *
 * @param {integer} i - Zero-index key indicating the position of the button in the map
 * @param {integer} currentSlider - Current slide integer, conditionally shows an accessible `currentlyOnScreen` message
 *
 * @example
 * import CustomPaging from './CustomPaging';
 *
 * const sliderSettings = {
 *  ...stuff,
 *  customPaging: CustomPaging,
 *  ...otherStuff
 * };
 */

const CustomPaging = (i, currentSlide) => {
    const print = i + 1;

    return (
        <button
            type="button"
            aria-label={
                currentSlide === i
                    ? `${miniGazelle.label('currentlyOnScreen', true)} ${miniGazelle.label('slide', true)}: ${print}`
                    : `${miniGazelle.label('slide', true)}: ${print}`
            }
        ></button>
    );
};

CustomPaging.propTypes = {
    i: PropTypes.number.isRequired,
    currentSlide: PropTypes.number.isRequired,
};

export default CustomPaging;
